<template>
  <div class="radio-tree-select" v-click-outside="onClickOutside">
    <div class="title">{{ title }}</div>
    <div class="radio-select" :class="{ active: optionShow }">
      <div class="selected-label" @click="handleClick">
        <span v-if="!selectedValue.name" class="placeholder">{{
          $t('search_please_choose')
        }}</span>
        <span v-else>{{ selectedValue.name }}</span>
        <img
          :class="{ 'arrow-up': optionShow }"
          src="@/assets/icons/Drop.svg"
          alt=""
        />
      </div>
      <transition name="fade">
        <div
          v-show="optionShow"
          class="filter-options"
          :style="styleOptionWidth"
        >
          <div class="filter">
            <input
              type="text"
              ref="input"
              v-model="filterText"
              :placeholder="$t('tree_search')"
            />
            <div
              v-if="filterText.length > 0"
              class="clear"
              @click="clearFilter"
            ></div>
            <div v-else class="search-icon"></div>
          </div>
          <div class="options">
            <div class="device-tree">
              <RadioTree
                v-model="selectedValue"
                :data="deviceTreeList"
                :groupList="groupList"
                :filterText="filterText"
                :isGroupSelect="isGroupSelect"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RadioTree from './RadioTree.vue'
import vClickOutside from 'v-click-outside'

export default {
  name: 'DeviceRadioTree',
  components: { RadioTree },
  props: [
    'value',
    'treeList',
    'title',
    'isGroupSelect',
    'isPublic',
    'optionWidth'
  ],
  data() {
    return {
      optionShow: false,
      filterText: ''
    }
  },
  computed: {
    ...mapState(['groupList']),
    ...mapState('account', ['deviceTreeList']),
    selectedValue: {
      get() {
        return this.value
          ? this.value
          : {
              id: '',
              name: '',
              groupId: '',
              isHasChild: false
            }
      },
      set(val) {
        this.$emit('input', val)
        this.optionShow = false
      }
    },
    publicParam() {
      // 0: private, 1: public
      return this.isPublic ? 1 : 0
    },
    styleOptionWidth() {
      return {
        width: this.optionWidth ? this.optionWidth : '100%'
      }
    }
  },
  watch: {
    optionShow() {
      if (this.optionShow) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted() {
    this.getDeviceTreeList({
      public: this.publicParam,
      filterText: this.filterText
    })
  },
  methods: {
    ...mapActions('account', ['getDeviceTreeList']),
    handleClick() {
      this.optionShow = !this.optionShow
    },
    clearFilter() {
      this.filterText = ''
      this.$refs.input.focus()
    },
    inputMouseDown($event) {
      $event.preventDefault()
      this.$refs.input.focus()
      this.optionShow = true
    },
    onClickOutside() {
      this.optionShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}

.title {
  color: #ffffff;
  font-size: px2rem(18);
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 4px;
}

.radio-select {
  width: 100%;
  position: relative;
  font-size: px2rem(18);
}

.selected-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #ffffff;
  color: #191919;
  border-radius: 8px;
  padding: 0 12px;
  font-weight: 300;
  line-height: 48px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;

  img {
    width: 20px;
    height: 20px;
    @include filter_191919;
    transition: all 200ms ease-in-out;

    &.arrow-up {
      transform: rotate(180deg);
    }
  }
}

.placeholder {
  color: #9d9d9d;
}

.filter-options {
  position: absolute;
  left: 0;
  top: calc(100% + 6px);
  width: 100%;
  color: #ffffff;
  background: #151b35;
  border: 1px solid #4a5c78;
  border-radius: 4px;
  z-index: 2;
  transition: all 200ms ease-in-out;
}

.filter {
  display: flex;
  align-items: center;
  padding: 6px 12px 6px 16px;
  border-bottom: 1px solid #4a5c78;
}

.filter span {
  font-weight: 300;
  color: #9d9d9d;
  margin-right: 6px;
}

.filter input {
  flex: 1;
  width: 100%;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: px2rem(18);
  font-weight: 300;
  border-radius: 4px;
  height: 36px;
  padding-right: 24px;
  margin-right: 8px;
  background: #151b35;
}

.clear {
  position: absolute;
  right: 20px;
  top: 18px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  background-image: url('../../../assets/icons/clear.svg');
  background-size: 12px;
}

.clear:hover {
  background-image: url('../../../assets/icons/clear-yellow.svg');
}

.search-icon {
  width: 22px;
  height: 22px;
  background-image: url('../../../assets/icons/feather-search-gray.svg');
  cursor: pointer;
}

.search-icon:hover {
  background-image: url('../../../assets/icons/feather-search-hover.svg');
}

.options {
  padding-right: 2px;
}

.device-tree {
  margin: 0;
  padding: 6px 4px 12px;
  max-height: calc(100vh - 300px);
  overflow: overlay;
}

ul {
  list-style-type: none;
  line-height: 32px;
  font-weight: 300;
  color: #ffc600;
}

.no-data {
  text-align: center;
}

@media screen and (max-width: 1440px) {
  .title {
    font-size: 18px;
  }

  .placeholder {
    font-size: 18px;
  }

  .filter input {
    font-size: 18px;
  }

  .radio-select {
    font-size: 18px;
  }
}

@media screen and (max-width: 840px) {
  .title {
    font-size: 18px;
  }

  .radio-select {
    font-size: 18px;
  }

  .filter-options {
    width: 200%;
  }
}
</style>