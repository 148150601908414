<template>
  <div class="receiver-tree">
    <el-tree
      ref="deviceTree" 
      :data="data" 
      :props="defaultProps"
      node-key="id"
      :empty-text="$t('tree_no_data')" 
      :expand-on-click-node="false"
      :default-expanded-keys="expandKeys"
      :filter-node-method="filterNode"
      @node-click="handleNodeClick"
      @node-expand="handleNodeExpand"
      @node-collapse="handleNodeCollapse">
      <div class="custom-tree-node" slot-scope="{ node, data }">
        <div v-if="(isGroupSelect && data.children) || !data.children" class="wrap-div">
          <div class="circle" :class="{ active: data.id === selectedObj.id }"></div>
        </div>
        <div v-if="data.children" class="wrap-div">
          <img src="@/assets/icons/Group-yellow.svg" alt="">
        </div>
        
        <span :class="{'has-child': data.children}">{{ node.label }}</span>
      </div>
    </el-tree>
  </div>
</template>

<script>
export default {
  name: 'RadioTree',
  props: {
    value: {
      type: Object
    },
    data: {
      type: Array
    },
    groupList: {
      type: Array
    },
    filterText: {
      type: String,
      default: ''
    },
    isGroupSelect: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      expandKeys: [],
      clicks: 0,
      clickTimer: null
    }
  },
  computed: {
    selectedObj: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'data.length'() {
      if (this.data?.length > 0) {
        this.expandKeys = [this.data[0].id]
      }
    },
    filterText(val) {
      this.$refs.deviceTree.filter(val)
    },
  },
  mounted() {
    if (this.data && this.data.length > 0)
      this.expandKeys = [this.data[0].id]
  },
  methods: {
    filterNode(val, data) {
      let checkedNodes = this.$refs.deviceTree.getCheckedNodes()
      let checked = checkedNodes.findIndex(node => node.id === data.id) >= 0
      
      let filtered = true
      if (this.filterText !== '') {
        const filter = this.filterText.toLowerCase()
        filtered = data.label.toLowerCase().includes(filter)
        const groupName = this.groupList.find(g => g.id === data.groupId).name
        filtered = filtered || groupName.toLowerCase().includes(filter)
      }
      return checked || filtered
    },
    nodeSingleClick(data, node) {
      if (!node.expanded) {
        this.expandKeys.push(data.id)
      }

      if (!this.isGroupSelect && data.children) return

      // 若點到已選取node，則取消該node
      if (data.id === this.selectedObj.id && data.groupId === this.selectedObj.groupId) {
        this.selectedObj = {
          id: '',
          name: '',
          groupId: '',
          isHasChild: false,
        }
      } else {
        const obj = {
          id: data.id,
          name: data.label, // 目前先帶label(videoTitle + user.id)
          groupId: data.groupId,
          isHasChild: (data.children && data.children.length > 0) ? true : false,
        }
        this.selectedObj = obj
      }
    },
    nodeExpand(data, node) {
      if (!node.expanded) {
        this.expandKeys.push(data.id)
      } else {
        // 要先把子層都收合，父層才可以收合
        node.childNodes.forEach(n => {
          if (n.expanded) {
            this.nodeCollapse(n)
          }
        })
        this.expandKeys = this.expandKeys.filter(v => v !== node.data.id)
        node.expanded = false
      }
    },
    nodeCollapse(node) {
      node.childNodes.forEach(n => {
        if (n.expanded) {
          this.nodeCollapse(n)
          this.expandKeys = this.expandKeys.filter(v => v !== n.data.id)
          n.expanded = false
        }
      })
      this.expandKeys = this.expandKeys.filter(v => v !== node.data.id)
      node.expanded = false
    },
    handleNodeClick(data, node) {
      this.clicks++
      if (this.clicks === 1) {
        this.clickTimer = setTimeout(() => {
          // single click: 點選設定設備
          this.nodeSingleClick(data, node)
          this.clicks = 0
        }, 300)
      } else {
        clearTimeout(this.clickTimer)
        this.clickTimer = null
        // double click：展開該群組
        if (data.children) {
          this.nodeExpand(data, node)
        }
        this.clicks = 0
      }
    },
    handleNodeExpand(data) {
      const index = this.expandKeys.indexOf(data.id)
      if (index === -1) {
        this.expandKeys.push(data.id)
      }
    },
    handleNodeCollapse(data) {
      const index = this.expandKeys.indexOf(data.id)
      if (index > -1) {
        this.expandKeys.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

/* el-tree */
::v-deep .el-tree {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background: #151B35;
  overflow: overlay;
}

/* 設定點擊才不會閃白底 */
::v-deep .el-tree .el-tree-node .el-tree-node__content {
  background: #151b35;
}

::v-deep .el-tree-node {
  white-space: normal;
}

::v-deep .el-tree-node__content {
  height: 100%;
  padding: 6px 0;
}

::v-deep .el-tree-node__label {
  font-size: 20px;
  font-weight: 300;
  line-height: 40px;
  color: #ffffff;
}

/* 調整三角形大小 */
::v-deep .el-tree-node__expand-icon {
  font-size: 20px;
}

/* 調整三角形顏色需排除 */
::v-deep .el-tree-node__expand-icon:not(.is-leaf) {
  color: #ffffff;
}

/* 父層文字顏色 */
::v-deep .el-tree-node__expand-icon:not(.is-leaf) ~ .el-tree-node__label {
  color: #FFC600;
}

::v-deep .el-checkbox__inner {
  border-color: #191919;
}

::v-deep .el-tree-node__content:hover {
  color: #ffffff;
  background: #384C83;
}

::v-deep .el-tree-node.is-current > .el-tree-node__content {
  color: #ffffff;
  background: #384C83;
}

.custom-tree-node {
  display: flex;
  align-items: flex-start;
}

.custom-tree-node img {
  margin-right: 8px;
}

.custom-tree-node span {
  line-height: 32px;
}

.wrap-div {
  height: 32px;
  display: flex;
  align-items: center;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  margin-right: 10px;
  position: relative;
  flex-shrink: 0;
}

.active {
  border-color: #FFC600;
}

.active::before {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FFC600;
  left: 4px;
  top: 4px;
}

.has-child {
  color: #FFC600;
  font-weight: 400;
}
</style>